import React from 'react'
import Gallery from '@components/Gallery';
import PortofolioLayout from '@components/PortofolioLayout';
import mockImg from "@images/projects/sotonite/frame-mock.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
const screenshots = importAll(require.context("@images/projects/sotonite/ss", false, /\.(png|jpg|svg)$/));
function importAll(r) {
    return r.keys().map(r);
}

const sotonitePage = ({ children, location }) => {

    return (
        <PortofolioLayout title="Sotonite">
            <div className="portofolio-project-wrapper ">
                <div className="portofolio-project sotonite ">
                    <img style={{maxWidth: '350px'}} src={mockImg} alt="sotonite Screenshots" />
                    <div className="project-section project-intro">
                        <h2>Introduction</h2>
                        <p>
                            This project was part of the Open Data Innovation module in my last university year. The task was to develop any application that uses 2 different sources of open data and brings value to the people in Southampton.
                            I was the team leader of my group, a group of 5 students. We developed 3 applications - a Node.js, a web (Angular) and mobile (Ionic) application. The Node.js app manipulates the open data retrieved from different sources and serves it to the mobile and web applications where it is displayed.
                            <br/>
                            Our app, called Sotonite, gets the user's location and shows it on a map, together with the location of several other points of interest (such as ATMS, restaurants, bars, etc.). Furthermore, the application also allows the user to find contact information for services such as taxi companies or police.
                        </p>
                        <div className="project-tags">
                            <span>#Ionic</span>
                            <span>#Node.js</span>
                            <span>#GoogleMaps</span>
                        </div>
                    </div>
                    <div className="project-section project-features">
                        <h2>Features</h2>
                        <ul>
                            <li><FontAwesomeIcon icon="check"></FontAwesomeIcon>Using Open Data</li>
                            <li><FontAwesomeIcon icon="check"></FontAwesomeIcon>Node.js Backend</li>
                            <li><FontAwesomeIcon icon="check"></FontAwesomeIcon>Using Google Maps SDK</li>
                        </ul>
                    </div>
                    <div className="project-section project-screenshots">
                        <h2>Screenshots</h2>
                        <Gallery images={screenshots} />
                    </div>
                </div>
            </div>
        </PortofolioLayout>
    )
};

export default sotonitePage;